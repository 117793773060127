exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-page-index-tsx": () => import("./../../../src/pages/HomePage/index.tsx" /* webpackChunkName: "component---src-pages-home-page-index-tsx" */),
  "component---src-pages-home-page-widgets-client-work-tsx": () => import("./../../../src/pages/HomePage/widgets/ClientWork.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-client-work-tsx" */),
  "component---src-pages-home-page-widgets-contact-tsx": () => import("./../../../src/pages/HomePage/widgets/Contact.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-contact-tsx" */),
  "component---src-pages-home-page-widgets-goals-tsx": () => import("./../../../src/pages/HomePage/widgets/Goals.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-goals-tsx" */),
  "component---src-pages-home-page-widgets-hero-section-tsx": () => import("./../../../src/pages/HomePage/widgets/HeroSection.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-hero-section-tsx" */),
  "component---src-pages-home-page-widgets-meet-object-tsx": () => import("./../../../src/pages/HomePage/widgets/MeetObject.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-meet-object-tsx" */),
  "component---src-pages-home-page-widgets-process-tsx": () => import("./../../../src/pages/HomePage/widgets/Process.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-process-tsx" */),
  "component---src-pages-home-page-widgets-strengths-tsx": () => import("./../../../src/pages/HomePage/widgets/Strengths.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-strengths-tsx" */),
  "component---src-pages-home-page-widgets-testimonial-tsx": () => import("./../../../src/pages/HomePage/widgets/Testimonial.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-testimonial-tsx" */),
  "component---src-pages-home-page-widgets-user-section-tsx": () => import("./../../../src/pages/HomePage/widgets/UserSection.tsx" /* webpackChunkName: "component---src-pages-home-page-widgets-user-section-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

